import React from 'react';
import { useTranslation } from "react-i18next";
import { Space, Row, Col } from "antd";
import { nanoid } from "nanoid";
import { formLayout } from "zlink-front";
// const { handleSpaces } = utils
const { getFormLayoutLast } = formLayout;

/**
 * @param {string} layoutType - 表单使用的布局类型
 * @param {Boolean} isDefaultSubmitter - 是否使用默认提交按钮
 * @param {Object} validationRules - 自定义校验规则对象
 * 
 * 
 * validationRules={{
 *   // content: {
 *   //   zh_CN_length: 2,
 *   //   en_US_length: 4,
 *   //   zh_CN_errorMsg: 'zh_CN_errorMsg',
 *   //   en_US_errorMsg: 'en_US_errorMsg',
 *   // }
 * }}
 */

const withForm = (WrappedComponent) => {
  const HOC = (props) => {
    // validationRules = {}
    const { layoutType, isDefaultSubmitter, submitter, ...restProps } = props;
    const { t, i18n } = useTranslation();
    const formLayout = getFormLayoutLast(layoutType, i18n.language === 'zh-CN' ? 'horizontal' : 'vertical');

    // 获取当前语言是否为中文
    // const isChinese = process?.env?.REACT_APP_LANGUAGE === 'zh-CN';=
    return (
      <WrappedComponent
        {...formLayout.form}
        submitter={
          isDefaultSubmitter && !submitter
            ? { 
              searchConfig: {
                resetText: t('common.table.reset'),
                submitText: t('common.button.save'),
              },
              render: (_, doms) => {
                return (
                  <Row>
                    <Col {...formLayout.button}>
                      <Space key={nanoid()}>{doms}</Space>
                    </Col>
                  </Row>
                );
              },
            }
            : submitter
        }
        {...restProps}
      >
        { props.children }
      </WrappedComponent>
    );
  };

  return HOC;
};

export default withForm;