// 基础库
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// 项目依赖
import { languages } from "zlink-front";
import { deepObjectMerge } from "@/utils/index"

// V1 语言文件
import en_US from "./languages/v1/en_US.json";
import zh_CN from "./languages/v1/zh_CN.json";
import th_TH from "./languages/v1/th_TH.json";
import id_ID from "./languages/v1/id_ID.json";
import vi_VN from "./languages/v1/vi_VN.json";
import es_ES from "./languages/v1/es_ES.json";
import pt_BR from "./languages/v1/pt_BR.json";

// V2 语言文件
// import en_US_v2 from "./languages/v2/en_US.json";
// import zh_CN_v2 from "./languages/v2/zh_CN.json";

// V1 海外校对版
// import en_US_v1_calibrate from "./languages/v1-calibrate/en_US.json";
// import zh_CN_v1_calibrate from "./languages/v1-calibrate/zh_CN.json";

const resources = {
  'en-US': {
    translation: deepObjectMerge(languages.enUS, en_US)
  },
  'zh-CN': {
    translation: deepObjectMerge(languages.zhCN, zh_CN)
  },
  'th-TH': {
    translation: deepObjectMerge(languages?.thTH, th_TH)
  },
  'id-ID': {
    translation: deepObjectMerge(languages?.idID, id_ID)
  },
  'vi-VN': {
    translation: deepObjectMerge(languages?.viVN, vi_VN)
  },
  'es-ES': {
    translation: deepObjectMerge(languages?.esES, es_ES)
  },
  'pt-BR': {
    translation: deepObjectMerge(languages?.ptBR, pt_BR)
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem('language') || 'zh-CN',
  interpolation: {
    escapeValue: false
  }
});

export default i18n;