import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { commonRoutes, routeData } from "./modules";
import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import enGB from 'antd/lib/locale/en_GB';
import zhTW from 'antd/lib/locale/zh_TW';
import idID from 'antd/lib/locale/id_ID';
import viVN from 'antd/lib/locale/vi_VN';
import thTH from 'antd/lib/locale/th_TH';

import { DefaultCustom } from "zlink-front";
import NotViewIcon from "@/assets/images/not-view.png";

const langOptions = {
  "en-US": enGB,
  "zh-CN": zhCN,
  "zh-TW": zhTW,
  "id-ID": idID,
  "vi-VN": viVN,
  "th-TH": thTH
}

const ZRouter = (props) => {
  const { i18n } = useTranslation();
  const [routes] = useState(routeData())
  const getPermissionsRoute = (routes, permissions) => {
    const res = routes.filter(item => item.meta?.roleCode && permissions.includes(item.meta.roleCode));
    return res;
  };
  const permittedRoutes = getPermissionsRoute(routes, props.permissions);
  const defaultRoute = permittedRoutes.length > 0 ? permittedRoutes[0].path : '/'; // Default fallback
  if (permittedRoutes.length === 0) {
    return <div style={{ height: 'calc(100vh - 60px)' }}>
      <DefaultCustom icon={NotViewIcon} />
    </div>
  }

  return (
    <>
      <ConfigProvider locale={langOptions[i18n.language]}>
        <Router basename={window.__POWERED_BY_QIANKUN__ ? '/zkbio_vis' : '/'}>
          <Routes>
            {
              commonRoutes.map(item => {
                return <Route key={item.path} path={item.path} element={item.element} />
              })
            }
            <Route path="/" element={<Navigate to={defaultRoute} />} />
          </Routes>
        </Router>
      </ConfigProvider>
    </>
  )
}

const mapStateToProps = ({ account }) => ({
  token: account.token,
  permissions: account.permissions,
})

export default connect(
  mapStateToProps
)(ZRouter)