import App from "@/App";
import NotFound from "@/pages/404";
import VisitorAuth from "@/pages/vis/visitorAuth";
import VisitorInvitationList from "@/pages/vis/VisitorInvitationList";
import VisitorArrivalRecord from "@/pages/vis/VisitorArrivalRecord";
import VisitorRegistration from "@/pages/vis/VisitorRegistration";
import PermissionGroupManagement from "@/pages/vis/PermissionGroupManagement";

import pwdtIcon from "@/assets/svg/ic_personal_pwd.svg";
import orgIcon from "@/assets/svg/ic_personal_organization.svg";
import workIcon from "@/assets/images/ic_menu_workbench@2x.png";
import positionIcon from "@/assets/svg/ic_side_position.svg";


import templateIcon from "@/assets/svg/ic_side_template.svg";
export const commonRoutes = [
  {
    name: 'vis',
    path: '/*',
    meta: {
      i18nKey: "router.visitor",
    },
    element: <App />
  },
  {
    name: 'notFound',
    path: '/notFound',
    meta: {
      i18nKey: "router.notFound",
    },
    element: <NotFound />
  }
]

export const innerRoutes = [
  {
    name: 'vis',
    redirect: '/vis/visitorAuth',
    meta: {
      i18nKey: 'router.visitor',
      roleCode: "vis",
      icon: <img src={workIcon} alt="" />
    },
    hidden: true,
    children: [
      // {
      //   name: 'visitorAuth',
      //   path: '/vis/visitorAuth',
      //   meta: {
      //     i18nKey: 'router.visitorAuth',
      //     roleCode: "vis:visitorAuth"
      //   },
      //   element: <VisitorAuth />
      // },
      // {
      //   name: 'VisitorRegistration',
      //   path: '/vis/VisitorRegistration',
      //   meta: {
      //     i18nKey: 'router.VisitorRegistration',
      //     // roleCode: "vis:VisitorRegistration"
      //     icon: <img src={positionIcon} alt="" />,

      //   },
      //   element: <VisitorRegistration />
      // },
      {
        name: 'VisitorInvitationList',
        path: '/vis/VisitorInvitationList',
        meta: {
          i18nKey: 'router.VisitorInvitationList',
          roleCode: "vis:visReservation",
          icon: <img src={pwdtIcon} alt="" />,

        },
        element: <VisitorInvitationList />
      },
      {
        name: 'VisitorArrivalRecord',
        path: '/vis/VisitorArrivalRecord',
        meta: {
          i18nKey: 'router.VisitorArrivalRecord',
          roleCode: "vis:transaction",
          icon: <img src={orgIcon} alt="" />,

        },
        element: <VisitorArrivalRecord />
      }
      ,
      {
        name: 'PermissionGroupManagement',
        path: '/vis/PermissionGroupManagement',
        meta: {
          i18nKey: 'router.PermissionGroupManagement',
          icon: <img src={templateIcon} alt="" />,
          roleCode: "vis:level"
        },
        element: <PermissionGroupManagement />
      }
    ]
  }
]

const handleRoute = (data) => {
  let newData = []
  data.forEach(item => {
    if (!item?.children && item?.path) {
      newData.push(item)
    } else {
      newData = newData.concat(handleRoute(item?.children) || [])
    }
  })
  return newData
}

export const routeData = () => {
  return handleRoute(innerRoutes)
}
