import { auth }  from "zlink-front"; 
import moment from "moment";
import { switchCompany } from "@/api/org";
import { accountLogout } from "@/api/user";

export const UPDATEACCOUNTINFO = 'account/UPDATEACCOUNTINFO'
export const UPDATEORGINFO = 'account/UPDATEORGINFO'
export const UPDATEPERMISSIONS = 'account/UPDATEPERMISSIONS'
export const UPDATETOKEN = 'account/UPDATETOKEN'
export const ACCOUNTLOGOUT = 'account/ACCOUNTLOGOUT'
export const INITPERMISSIONS = 'account/INITPERMISSIONS'

const userState = {
  accountInfo: auth?.getAccountInfo() || {},      // 账号信息
  token: auth?.getToken() || '',                  // 账号登录-token
  refreshToken: auth?.getRefreshToken() || '',    // 账号登录-刷新token
  orgInfo: auth?.getOrgInfo() || {},              // 公司组织信息
  permissions: auth?.getPermissions() || [],        // 权限菜单标识编码
  expires: auth?.getExpires() || moment().valueOf()               // cookie 往后推的过期时间
}
//eslint-disable-next-line
export default (state = userState, action = {}) => {
  const newExpires = !!action.data?.expires_in ? 
                     new Date().getTime() + action.data?.expires_in  * 1000 : 
                     new Date().getTime() + state.accountInfo?.expires_in  * 1000
  switch (action.type) {
    case UPDATEACCOUNTINFO:
      // auth.removeAll()
      // auth.setToken(action.data.access_token, newExpires)
      // auth.setRefreshToken(action.data.refresh_token, newExpires)
      // auth.setExpires(newExpires)
      auth.setAccountInfo(action.data)
      return {
        ...state,
        accountInfo: action.data,
        // token: action.data.access_token,
        // refreshToken: action.data.refresh_token,
        // expires: newExpires
      }
    case ACCOUNTLOGOUT:
      !!action?.data?.notInterface ? auth.removeAll() : logout()
      return {
        ...state,
        accountInfo: {},
        token: '',
        orgInfo: {},
        permissions: [],
        expires: moment().valueOf() 
      }
    case UPDATEORGINFO:
      const newData = {
        ...state.orgInfo,
        ...action.data
      }
      auth.setOrgInfo(newData)
      return {
        ...state,
        orgInfo: newData
      }
    case UPDATETOKEN:
      auth.setToken(action.data.access_token, newExpires)
      auth.setRefreshToken(action.data.refresh_token, newExpires)
      auth.setExpires(newExpires)
      return {
        ...state,
        token: action.data.access_token,
        refreshToken: action.data.refresh_token,
        expires: newExpires
      }
    case UPDATEPERMISSIONS:
      auth.setPermissions(action?.data || [])
      return {
        ...state,
        permissions: action?.data || []
      }
    case INITPERMISSIONS: 
      return {
        ...state,
        permissions: auth?.getPermissions()
      }
    default:
      return state
  }
}

export const updateAccountInfo = (data) => {
  return dispatch => {
    dispatch({
      type: UPDATEACCOUNTINFO,
      data: data 
    })
  }
}

export const handleAccountLogout = (data) => {
  return dispatch => {
    dispatch({
      type: ACCOUNTLOGOUT,
      data: data 
    })
  }
}

export const updateOrgInfo = (data) => {
  return dispatch => {
    dispatch({
      type: UPDATEORGINFO,
      data: data 
    })
  }
}

export const updateToken = (data) => {
  return dispatch => {
    dispatch({
      type: UPDATETOKEN,
      data: data 
    })
  }
}

export const updatePermissions = (data) => {
  return dispatch => {
    dispatch({
      type: UPDATEPERMISSIONS,
      data: data 
    })
  }
}

export const initPermissions = () => {
  return dispatch => {
    dispatch({
      type: INITPERMISSIONS
    })
  }
}

// 登出
const logout = async () => {
  try {
    await accountLogout()
    auth.removeAll()
  } catch (e) {
    console.log(e)
    auth.removeAll()
  }
};