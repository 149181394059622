import React, { useState } from 'react';
import { Button } from 'antd';
import { connect } from 'react-redux';


/**
 * PermissionButton 组件根据用户权限显示按钮，并在特定条件下启用加载效果。
 * 
 * @component
 * @param {Object} props - 组件的属性。
 * @param {Array<string>} [props.roles] - 用户角色权限数组，用于检查按钮是否显示及是否启用加载效果。如果数组为空，表示不进行权限控制。
 * @param {React.ReactNode} props.children - 按钮的子元素内容。
 * @param {Function} [props.onClick] - 按钮的点击事件处理函数。
 * @param {Object} [props.restProps] - 传递给按钮的其他属性，例如 `style`, `className` 等。
 * @param {string} [props.btnType='disabled'] - 按钮的类型，支持 'disabled'（禁用按钮）和 'hidden'（隐藏按钮）。默认为 'hidden'。
 * @param {Array<string>} [props.permissions] - 用户的权限数组，用于检查用户是否有操作按钮的权限。如果不传递此参数，则不进行权限控制。(取redux的值)
 * @param {boolean} [props.btnLoading=false] - 控制按钮是否在点击时显示加载状态。如果传递此属性，优先控制加载状态。
 * @returns {React.ReactElement} 返回一个根据权限控制的按钮组件，支持加载效果。
 * 
 * @example
 * // 示例：没有权限控制，按钮永远可见
 * <PermissionButton onClick={() => console.log('点击按钮')}>
 *   按钮
 * </PermissionButton>
 * 
 * @example
 * // 示例：根据权限控制按钮显示和禁用
 * <PermissionButton roles={['admin']} permissions={['admin']} onClick={() => console.log('点击按钮')}>
 *   管理员按钮
 * </PermissionButton>
 * 
 * @example
 * // 示例： 按钮loading
 * <PermissionButton roles={['admin']} btnLoading onClick={() => console.log('点击按钮')}>
 *   加载按钮
 * </PermissionButton>
 */
const PermissionButton = ({ roles = [], children, onClick, btnType = 'hidden', permissions = [], btnLoading = false, ...restProps }) => {
  const [loading, setLoading] = useState(false);

  // 权限控制逻辑
  const hasUnrestrictedPermission = permissions.includes('unrestricted');
  const hasLoadingRole = roles.includes('loading') || btnLoading;

  // 如果没有传递 roles 或者 roles 为空数组，不进行权限控制
  const isEnabled = (roles.length === 0 || hasUnrestrictedPermission || roles.some(role => permissions.includes(role)));

  // 点击处理函数
  const handleOnClick = async (e) => {
    if (onClick) {
      setLoading(true);
      try {
        await onClick(e);
      } catch (error) {
        // 错误处理
      } finally {
        setLoading(false);
      }
    }
  };

  // 如果权限数据尚未加载，显示加载指示器
  if (!isEnabled && btnType === 'hidden') {
    return null;  // 没有权限时不渲染按钮
  }

  // 根据按钮类型渲染
  if (btnType === 'disabled') {
    return (
      <Button
        {...restProps}
        loading={loading && hasLoadingRole}
        onClick={handleOnClick}
        disabled={!isEnabled || restProps.disabled}
      >
        {children}
      </Button>
    );
  }

  if (btnType === 'hidden') {
    return isEnabled ? (
      <Button
        {...restProps}
        loading={loading && hasLoadingRole}
        onClick={handleOnClick}
      >
        {children}
      </Button>
    ) : null;  // 如果没有权限则不渲染按钮
  }

  return <></>;  // 需要保证返回 null 的情况是预期的
};

const mapStateToProps = ({ account }) => ({
  permissions: account.permissions,
});

export default connect(mapStateToProps)(PermissionButton);
