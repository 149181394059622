import React, { useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Button, Modal, message } from "antd";
import { ProTableCustom } from "zlink-front";
import "@/assets/scss/vis.scss";
import useHorizontalScroll from '../../../hook/useHorizontalScroll';
import PermissionGroupEditing from './components/PermissionGroupEditing';
import { PlusOutlined } from "@ant-design/icons";
import { addVisLevel, getVisLevelList, updateVisLevel, deleteVisLevel, addDoor, getDoorList, deleteDoor } from "@/api/acc";
import { getPermissionsStatus, calculateLastPageCount } from "@/utils/index";
import PermissionButton from '@/components/Basic/PermissionButton';

const { confirm } = Modal;

export default function Index() {
  const { t } = useTranslation();
  const actionRef = useRef();
  const [open, setOpen] = useState(false);
  useHorizontalScroll('ant-table-body', 300);
  const [selectedRow, setSelectedRow] = useState({});
  const [delList, setDelList] = useState([]);
  const [listRes, setListRes] = useState(null);

  const columns = [
    {
      title: t("vis.permissionGroupName"),
      key: "name",
      dataIndex: "name",
      hideInSearch: false,
    },
    {
      title: t("common.table.detail"),
      key: "operation",
      dataIndex: "operation",
      hideInSearch: false,
      fixed: 'right',
      width: 200,
      render: (_, record) => (
        <>
          <PermissionButton
            type="link" onClick={() => handleOpenDetails(record)}>
            {t('common.button.edit')}
          </PermissionButton>

          <PermissionButton
            type="link" danger onClick={() => handleDelete(record)}>
            {t('common.button.del')}
          </PermissionButton>
        </>
      ),
    },
  ];

  const handleOpenDetails = async (record) => {
    try {
      const params = {
        levelId: record.id,
        pageSize: 9999,
        pageNumber: 1
      };
      let { accessGroupDoors = [] } = await getDoorList(params) || {};
      const doorIds = accessGroupDoors.map((e) => e.doorId);
      setSelectedRow({
        ...record, type: 'set',
        doorIds,
        accessGroupDoors,
      });
      setOpen(true);
    } catch (error) {
      setSelectedRow({ ...record, type: 'set' });
      setOpen(true);
    }
  };

  const handleDelete = (record) => {
    confirm({
      title: t("vis.PermissionGroupManagement.confirmDelete"),
      okText: t("common.button.sure"),
      okType: "danger",
      cancelText: t("common.confirm.cancelText"),
      onOk: async () => {

        try {
          let res = await deleteVisLevel(record.id);
          res.code === "DMSI0000" ? message.success(t("common.result.delSuccess")) : message.warn(res.message || t("common.result.delFail"));
          const { setPageInfo, pageInfo, reload } = actionRef.current;
          const LastPageCount = calculateLastPageCount(listRes.totalCount, listRes.pageSize, listRes.totalPages);
          const DEL_LENGTH = 1;
          if (LastPageCount === DEL_LENGTH) {// 点击删除后，当前页为最后一页且删除的条数等于最后一页的条数，直接刷新
            reload();
          }
          if (listRes.totalPages === pageInfo.current && DEL_LENGTH >= LastPageCount) {
            setPageInfo({
              ...pageInfo,
              current: pageInfo.current <= 1 ? 1 : pageInfo.current - 1
            });
          } else {
            reload();
          }
        } catch (error) {
          console.log('error: ', error);
          message.error(t("common.result.delFail"));
        }
      },
    });
  };

  const columnsWithEllipsis = columns.map(column => {
    const { render, ...rest } = column;
    const key = column.dataIndex;
    return {
      ...rest,
      ellipsis: true,
      render: (_, record) => {
        const text = record[key];
        return render ? render(_, record) : <span title={text}>{text}</span>;
      }
    };
  });

  const onSubmit = async (values) => {
    let group = selectedRow.type === 'add' ?
      await addVisLevel({ ...values }) :
      await updateVisLevel(selectedRow.id, { schedulerId: selectedRow.scheduler_id, ...values });

    group && message.success(t("common.result.saveSuccess"));

    values.doorIds && values.doorIds.length > 0 && await addDoor({ levelId: selectedRow.type === 'add' ? group.id : selectedRow.id, doorIds: values.doorIds });

    selectedRow.type === 'set' && delList.length > 0 && await onDeselect(delList);
    setOpen(false);
    actionRef.current.reload();
  };

  const onDeselect = async (value) => {
    if (selectedRow.id) {
      deleteDoor({ levelId: selectedRow.id, doorIds: [...value] });
    }
  };

  return (
    <div className='vis-invitation-list'>
      <ProTableCustom
        columns={columnsWithEllipsis}
        actionRef={actionRef}
        request={async (params = {}, sort, filter) => {
          try {
            const res = await getVisLevelList({
              pageNumber: params.current,
              pageSize: params.pageSize,
              name: params?.searchParams || "",
            }) || {};

            setListRes({
              len: res.accessGroups ? res.accessGroups.length : 0,
              pageSize: params.pageSize,
              totalPages: res.totalPages,
              totalCount: res.totalCount,
            });

            return {
              data: res.accessGroups || [],
              success: true,
              total: res.totalCount,
            };
          } catch (error) {
            return {
              data: [],
              success: true,
              total: 0,
            };
          }
        }}
        searchPlaceholder={t("vis.PermissionGroupManagement.searchPlaceholder")}
        rowKey="id"
        toolBar={
          [<PermissionButton
            key="button"
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => {
              setSelectedRow({ name: '', type: 'add', doorIds: [] });
              setOpen(true);
            }}
          >
            {t("common.button.add")}
          </PermissionButton>]
        }
      />
      {open && (
        <PermissionGroupEditing
          open={open}
          onSubmit={onSubmit}
          getDelList={(val) => setDelList(val)}
          initialValues={selectedRow}
          title={t("common.table.detail")}
          onClose={() => setOpen(false)}
        />
      )}
    </div>
  );
}
