import React, { useEffect, useState } from "react";
import {
  DatePicker,
  Radio,
  Drawer,
  notification,
  Button,
  Card,
  Tag,
  Space,
  Form,
  Row,
  Col,
} from "antd";
import ProForm, { ProFormText, ProFormCheckbox } from "@ant-design/pro-form";
import { addVisAuth, getVisAuth } from "@/api/vis";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { layout, ZKSelectDeviceOrDoor } from "zlink-front";
import Text from "antd/lib/typography/Text";
import { nanoid } from "nanoid";
import BasePhoneCodeSelect from "@/components/Basic/BasePhoneCodeSelect";
import { getRemoteOpenDoorList } from "@/api/acc";
import { fetchDevice } from "@/api/device";
const { defaultFormLayout } = layout;
const { RangePicker } = DatePicker;
const DetailDrawer = (props) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [phoneVerifyRules, setPhoneVerifyRules] = useState([
    { required: true, message: t("vis.visitorAuth.DetailDrawerAdd.phoneNotNull") },
    { max: t("vis.visitorAuth.DetailDrawerAdd.name50") },
  ]);
  const [dateTimeRangeStartValue, setDateTimeRangeStartValue] = useState(
    Date.now()
  );
  const [dateTimeRangeEndValue, setDateTimeRangeEndValue] = useState(
    Date.now() + 1000 * 60 * 60
  );
  const [openRangePicker, setOpenRangePicker] = useState(true);
  const [intervalRangePicker, setIntervalRangePicker] = useState(1);
  const [dateTimeRangeValue, setDateTimeRangeValue] = useState(Date.now());

  const [selectData, setSelectData] = useState({
    devices: [],
  });
  const [params, setParams] = useState({
    snList: [],
    deviceType: [],
  });
  const [phoneCode, setPhoneCode] = useState("");

  useEffect(() => {
    setShow(props.visible);
  }, [props.visible]);

  const reset = async (values) => {
    setSelectData({
      devices: [],
    });
    setParams({
      snList: [],
      deviceType: [],
    });
    setDateTimeRangeStartValue(new Date());
    setDateTimeRangeEndValue(Date.now() + 1000 * 60 * 60);
    setOpenRangePicker(true);
    setIntervalRangePicker(1);
    setDateTimeRangeValue(Date.now());
  };

  const finish = async (values) => {
    try {
      if (params.snList.length === 0) {
        notification.error({
          message: t("vis.visitorAuth.DetailDrawerAdd.authDeviceNotNull"),
        });
        return;
      }
      const res = await addVisAuth({
        visName: values.visName,
        visPhone: phoneCode + values.visPhone,
        authStartTime: dateTimeRangeStartValue,
        authEndTime: dateTimeRangeEndValue,
        visitorAuthDevice: params.snList,
        sendSms:
          values.sendSms !== undefined
            ? values.sendSms[0] === "1"
              ? true
              : false
            : false,
      });
      if (res !== "") {
        const visAuthInfoRes = await getVisAuth(res);
        props.setIsModalAuthCodeVisible(visAuthInfoRes.authCode);
      }
      onClose();
      props.reload();
      notification.success({
        message: t("common.result.opSuccess"),
      });
    } catch (error) {
      notification.error({
        message: t("common.result.opFail"),
      });
    }
  };

  const onClose = () => {
    setShow(false);
    props.close();
  };
  const [visible, setVisible] = useState({
    device: false,
  });
  const setDeviceVisibleFn = () => {
    setVisible({
      ...visible,
      device: false,
    });
  };

  // 选择设备参数
  const onModalChange = (value, type) => {
    setSelectData({
      ...selectData,
      [type]: [...selectData.devices, ...value],
    });
    handleParamsData(value, 'add');
  };

  const handleParamsData = (arr, type) => {
    const paramsType = "snList";
    let newData = type === 'add' ? [...params.snList] : []
    if (!!arr.length) {
      arr.forEach((item) => {
        newData.push({
          deviceSn: item.sn,
          deviceName: item.deviceAlias,
        });
      });
    }
    setParams({
      ...params,
      [paramsType]: newData,
    });
  };

  // 删除已选项
  const handleDelete = (idx, type) => {
    let arr = selectData[type].filter((_, i) => i !== idx);
    setSelectData({
      ...selectData,
      [type]: arr,
    });
    handleParamsData(arr, 'del');
  };

  const proFormRadioOnChange = (e) => {
    setIntervalRangePicker(e.target.value);
    if (e.target.value === 0) {
      setOpenRangePicker(false);
    } else {
      setDateTimeRangeEndValue(
        dateTimeRangeValue + 1000 * 60 * 60 * e.target.value
      );
    }
  };

  const sendSmsOnChange = (checkedValues) => {
    if (checkedValues[0] === "1") {
      setPhoneVerifyRules([
        { required: true, message: t("vis.visitorAuth.DetailDrawerAdd.phoneNotNull") },
        { max: t("vis.visitorAuth.DetailDrawerAdd.name50") },
      ]);
    } else {
      setPhoneVerifyRules([
        { required: false, message: t("vis.visitorAuth.DetailDrawerAdd.phoneNotNull") },
        { max: t("vis.visitorAuth.DetailDrawerAdd.name50") },
      ]);
    }
  };

  const onCalendarChange = (val) => {
    if (intervalRangePicker !== 0) {
      setDateTimeRangeStartValue(val[0].valueOf());
      setDateTimeRangeEndValue(
        val[0].valueOf() + 1000 * 60 * 60 * intervalRangePicker
      );
      setOpenRangePicker(true);
    } else {
      setDateTimeRangeStartValue(val[0].valueOf());
      setDateTimeRangeEndValue(val[1].valueOf());
    }
    setDateTimeRangeValue(val[0].valueOf());
  };

  const onRangePickerOpenChange = (val) => {
    if (intervalRangePicker !== 0) {
      if (val) {
        setOpenRangePicker(false);
        setDateTimeRangeEndValue("");
      } else {
        setOpenRangePicker(true);
        setDateTimeRangeEndValue(
          dateTimeRangeValue + 1000 * 60 * 60 * intervalRangePicker
        );
      }
    }
  };
  return (
    <>
      <Drawer
        title={t("common.button.add")}
        {...defaultFormLayout.drawer}
        {...defaultFormLayout.bodyStyle}
        onClose={onClose}
        open={show}
        className="zk-vis-add-drawer"
      >
        <ProForm
          {...defaultFormLayout.form}
          onFinish={finish}
          onReset={reset}
          submitter={{
            render: (props, doms) => {
              return (
                <Row>
                  <Col {...defaultFormLayout.button}>
                    <Space key={nanoid()}>{doms}</Space>
                  </Col>
                </Row>
              );
            },
          }}
        >
          <div className="zklink-drawer-title">
            <Text type="success">|</Text>
            {t("vis.visitorAuth.DetailDrawerAdd.info")}
          </div>
          <ProFormText
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 18 }}
            name={["visName"]}
            label={t("vis.visitorAuth.DetailDrawerAdd.name")}
            rules={[
              { required: true, message: t("vis.visitorAuth.DetailDrawerAdd.nameNotNull") },
              { max: t("vis.visitorAuth.DetailDrawerAdd.name50") },
            ]}
          />
          <BasePhoneCodeSelect
            handlePhoneCode={(value) => setPhoneCode(value)}
            label={t("vis.visitorAuth.DetailDrawerAdd.phone")}
            name="visPhone"
            rules={phoneVerifyRules}
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 18 }}
          />
          <div className="zklink-drawer-title">
            <Text type="success">|</Text>
            {t("vis.visitorAuth.DetailDrawerAdd.authorization")}
          </div>
          <ProFormText
            name={["radio"]}
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 18 }}
            label={t("vis.visitorAuth.DetailDrawerAdd.authorizationTime")}
          >

            <Radio.Group
              options={[
                {
                  label: t('vis.visitorAuth.DetailDrawerAdd.1_hour'),
                  value: 1,
                },
                {
                  label: t('vis.visitorAuth.DetailDrawerAdd.6_hours'),
                  value: 6,
                },
                {
                  label: t('vis.visitorAuth.DetailDrawerAdd.24_hours'),
                  value: 24,
                },
                {
                  label: t('vis.visitorAuth.DetailDrawerAdd.custom'),
                  value: 0,
                },
              ]}
              onChange={proFormRadioOnChange}
              value={intervalRangePicker}
              optionType="button"
              bodyStyle={{ width: '100%' }}
            />
          </ProFormText>
          <Form.Item
            label={t("vis.visitorAuth.DetailDrawerAdd.authorizationTime")}
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 18 }}
          >
            <RangePicker
              value={[
                moment(dateTimeRangeStartValue),
                moment(dateTimeRangeEndValue),
              ]}
              disabled={[false, openRangePicker]}
              showTime={{ defaultValue: moment("00:00:00", "HH:mm:ss") }}
              onChange={onCalendarChange}
              onOpenChange={onRangePickerOpenChange}
            />
          </Form.Item>
          <Form.Item
            width="md"
            label={t("vis.visitorAuth.DetailDrawerAdd.authorizationDevices")}
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 18 }}
          >
            <Button onClick={() => setVisible({ ...visible, device: true })}>
              {t('vis.visitorAuth.DetailDrawerAdd.add')}
            </Button>
            {!!selectData.devices.length && (
              <Card>
                <Space key={nanoid()}>
                  {selectData.devices.map((item, idx) => {
                    return (
                      <Tag
                        closable
                        key={item.sn}
                        onClose={() => handleDelete(idx, "devices")}
                      >
                        {item.deviceAlias}
                      </Tag>
                    );
                  })}
                </Space>
              </Card>
            )}

          </Form.Item>
          <ProFormCheckbox.Group
            name="sendSms"
            options={[{ label: t("vis.visitorAuth.DetailDrawerAdd.tipsSMS"), value: "1" }]}
            onChange={sendSmsOnChange}
          />
        </ProForm>
      </Drawer>

      {/* 选择设备 */}
      {visible.device && (
        <ZKSelectDeviceOrDoor
          visible={visible.device}
          onChange={onModalChange}
          close={setDeviceVisibleFn}
          params={{
            //   limit: 1,
            deviceType: "acc",
            // pageNumber: 1,
            // pageSize: 10
            //   key: 'sn'
          }}
          selectedKeys={params.snList}
          filterKey="deviceSn"
          fetchDevice={fetchDevice}
          getRemoteOpenDoorList={getRemoteOpenDoorList}
        />
      )}
    </>
  );
};

export default DetailDrawer;
