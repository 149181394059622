import React, { useState, useEffect } from "react";
import { Drawer, Form, Input, Select, Button } from "antd";
import { layout, ZKSelectDeviceOrDoor } from "zlink-front";
import { getRemoteOpenDoorList } from "@/api/acc";
import { fetchDevice } from "@/api/device";
import { useTranslation } from "react-i18next";
import { ProForm } from '@ant-design/pro-form';

import withForm from "@/hoc/withForm";
const ProFormHoc = withForm(ProForm)
const { defaultFormLayout } = layout;

export default function PermissionGroupEditing({ open, onClose, onSubmit, initialValues, getDelList }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [doorloading, setDoorloading] = useState(false);
  const [num, setValue] = useState(0);
  const [doorIdsMap, setDoorIdsMap] = useState([]);

  useEffect(() => {
    if (initialValues.type === 'add') {
      getDelList([]);
      return;
    }
    const selectList = form.getFieldValue("doorIds") || [];
    const allList = selectData.devices.map((e) => e.doorId) || [];
    const complementList = allList.filter((value) => !selectList.includes(value));

    getDelList(complementList);
  }, [form.getFieldValue("doorIds"), num]);

  const onDeselect = () => {
    setValue(num + 1);
  }

  const [device, setDevice] = useState({
    device: false,
  });
  const [selectData, setSelectData] = useState({
    devices: [],
  });

  const mapDoorData = (doors) => {
    return doors?.map((e) => ({
      label: e.deviceAlias,
      value: e.doorId,
    })) || [];
  };

  useEffect(() => {
    const fetchDoorData = async () => {
      setDoorloading(true);
      try {
        const res = await getRemoteOpenDoorList({
          pageNumber: 1,
          pageSize: 100,
        });

        const doors = res?.doors || [];
        const doorIdsMap = mapDoorData(doors);
        const accessGroupDoors = mapDoorData(initialValues?.accessGroupDoors);

        setSelectData({ devices: doors });
        setDoorIdsMap([...doorIdsMap, ...accessGroupDoors]);
      } catch (error) {
        console.error('Failed to fetch door data:', error);
      } finally {
        setDoorloading(false);
      }
    };

    fetchDoorData();
  }, [initialValues]);


  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues]);

  const handleSubmit = () => {
    form.validateFields()
      .then((values) => {
        // 清理 values 中字符串类型的字段值
        const cleanedValues = Object.keys(values).reduce((acc, key) => {
          const value = values[key];
          // 如果值是字符串，则去掉头尾空格
          acc[key] = typeof value === 'string' ? value.trim() : value;
          return acc;
        }, {});

        setLoading(true);
        onSubmit(cleanedValues)
          .finally(() => setLoading(false));
      })
      .catch((err) => {
        // 处理表单验证失败的情况
      });
  };


  const handleReset = () => {
    form.resetFields();
    getDelList([]);
  };
  // 选择设备参数
  const onModalChange = (value, type) => {
    const ids = value.map((e) => e.doorId || e)
    // const doorIds = form.getFieldValue("doorIds") || []
    // const data = Array.from(new Set([...ids, ...doorIds]))
    const data = Array.from(new Set([...ids]))
    console.log('data: ', data);
    form.setFieldsValue({
      doorIds: data
    });
  };

  const setDeviceVisibleFn = () => {
    setDevice({
      device: false,
    });
  };

  // 传入模态框的id 需要取权限组绑定的id 和权限组有权限的id的交集
  const selectDeviceModalValue = () => {
    try {
      const doorIds = form.getFieldValue("doorIds");
      const devices = selectData.devices.map((e) => e.doorId);
      const intersection = doorIds.filter(id => devices.includes(id));
      return intersection
    } catch (error) {
      console.log('error: ', error);
      return []
    }
  };
  return (
    <Drawer
      title={initialValues.type === 'set' ? t("vis.PermissionGroupEditing.editPermissionGroup") : t("vis.PermissionGroupEditing.addPermissionGroup")}
      open={open}
      onClose={onClose}
      width={500}
      destroyOnClose
    >
      <ProFormHoc
        form={form}
        isDefaultSubmitter={true}
        onReset={handleReset}
        onFinish={handleSubmit}
        initialValues={initialValues}
      >
        <Form.Item
          name="name"
          label={t("vis.permissionGroupName")}
          rules={[
            { required: true, message: t("vis.PermissionGroupEditing.enterPermissionGroupName") },
            { max: 20, message: t("vis.PermissionGroupEditing.permissionGroupNameMaxLength") },
          ]}
        >
          <Input placeholder={t("vis.PermissionGroupEditing.enterPermissionGroupName")}
            onChange={(e) => {
              const value = e.target.value;
              const newValue = value.replace(/^\s+|\s{2,}|\s+$/g, " ").trimStart();
              e.target.value = newValue; // 手动更新输入框的值
              form.setFieldsValue({ name: newValue }); // 更新表单的值
            }}
          />

        </Form.Item>
        {doorloading ?
          <Form.Item
            label={t("vis.PermissionGroupEditing.accessDevice")}
            rules={[{ required: false, message: t("vis.PermissionGroupEditing.selectAccessDevice") }]}
          >
            <Input placeholder={t("vis.loading")} />
          </Form.Item>
          :
          <Form.Item
            name="doorIds"
            label={t("vis.PermissionGroupEditing.accessDevice")}
            rules={[{ required: false, message: t("vis.PermissionGroupEditing.selectAccessDevice") }]}
          >
            <Select mode="multiple" placeholder={t("vis.PermissionGroupEditing.selectAccessDevice")}
              options={doorIdsMap || []}
              onDeselect={onDeselect}
              open={false}
              onClick={() => {
                setDevice({
                  device: true,
                });
              }}
            >
            </Select>
          </Form.Item>
        }

      </ProFormHoc>

      {device.device && (
        <ZKSelectDeviceOrDoor
          visible={device.device}
          onChange={onModalChange}
          close={setDeviceVisibleFn}
          type="door"
          selectedKeys={selectDeviceModalValue()}
          selecteds={selectDeviceModalValue()}
          fetchDevice={fetchDevice}
          getRemoteOpenDoorList={getRemoteOpenDoorList}
          filter={false}
          required={false}
        />
      )}
    </Drawer>
  );
}
