import { auth } from "zlink-front";

import positionIcon from "@/assets/images/home/ic_side_position@2x.png";
import roleIcon from "@/assets/images/home/ic_side_role@2x.png";
import memberIcon from "@/assets/images/home/ic_side_member@2x.png";
import equipmentIcon from "@/assets/images/home/ic_side_equipment@2x.png";
import bioTemplateIcon from "@/assets/images/home/ic_side_biology@2x.png";
import maintainIcon from "@/assets/images/home/ic_side_maintain@2x.png";
import accountlistIcon from "@/assets/images/home/ic_side_accountlist@2x.png";

export const LANG = {
  "en-US": "en-us",
  "zh-CN": "zh-cn",
  "zh-TW": "zh-tw",
  "th-TH": "th",
  "vi-VN": "vi",
  "id-ID": "id",
};

export const LANG_NAME = [
  {
    label: "简体",
    value: "zh-CN",
  },
  {
    label: "繁体",
    value: "zh-TW",
  },
  {
    label: "EN",
    value: "en-US",
  },
  {
    label: "ไทย", 
    value: "th-TH", //泰语
  },
  {
    label: "Tiếng Việt",
    value: "vi-VN", //越南
  },
  {
    label: "Bahasa Indonesia",
    value: "id-ID", // 印尼
  }
];

export const apiVersionList = {
  "v1.0": {
    zlink: "/zlink-api/v1.0/zlink",
    acc: "/acc-api/v1.0/zlink",
    vis: '/vis-api/v1.0/zlink'
  },
};

export const apiVersion = () => {
  const newProcess = auth?.getProcess();
  const version = newProcess?.env?.REACT_APP_API || "v1.0";
  return apiVersionList[version].zlink;
};
// export const apiVersion = () => {
//   const prefix = localStorage.getItem('visApiPrefix')
//   const newProcess = auth.getProcess();
//   const version = newProcess?.env?.REACT_APP_API || "v1.0";
//   return `${prefix}${apiVersionList[version].zlink}`
// };

export const apiAccVersion = () => {
  const newProcess = auth?.getProcess();
  const version = newProcess?.env?.REACT_APP_API || "v1.0";
  return apiVersionList[version].acc;
};

export const apiVisVersion = () => {
  const newProcess = auth?.getProcess();
  const version = newProcess?.env?.REACT_APP_API || "v1.0";
  return apiVersionList[version].vis;
};

// 设备类型选项
export const deviceTypeOptions = [
  { label: "考勤", value: "att" },
  { label: "门禁", value: "acc" },
  // { label: '门禁+考勤', value: 'acc+att' }
];

export const deviceTypeObj = {
  att: "考勤",
  acc: "门禁",
  "acc+att": "门禁+考勤",
};

export const deviceStatusObj = {
  offline: "离线",
  online: "在线",
};

// 应用路由
export const APP_ROUTER = {
  Card: "/card/electronic",
  Att: "/zkbio_att?stamp=" + new Date().getTime(),
  Acc: "/acc/remoteOpenDoor",
  Vis: "/vis/visitorAuth",
};

export const MICRO_ROUTER = ["zkbio_att"];

//快捷入口
export const QUICK_LIST = [
  {
    name: 'router.position',
    url: '/org/position',
    icon: positionIcon,
    code: 'pers:designation',
    roleCode: 'pers',
    isVerify: true
  },
  {
    name: 'router.role',
    url: '/org/role',
    icon: roleIcon,
    code: 'pers:role',
    roleCode: 'pers:role',
    isVerify: true
  },
  {
    name: 'router.person',
    url: '/org/person',
    icon: memberIcon,
    code: 'pers:employee',
    roleCode: 'pers',
    isVerify: true
  },
  {
    name: 'router.deviceList',
    url: '/device/deviceList',
    icon: equipmentIcon,
    code: 'dc:device',
    roleCode: 'dc',
    isVerify: true
  },
  {
    name: 'router.biologicalTemplate',
    url: '/org/biologicalTemplate',
    icon: bioTemplateIcon,
    code: 'dc:bioTemplate',
    roleCode: 'dc',
    isVerify: true
  },
  {
    name: 'router.opsDetail',
    url: '/sys/opsDetail',
    icon: maintainIcon,
    code: 'platform:partnerDetail',
    isVerify: false
  },
  {
    name: 'router.account',
    url: '/sys/account',
    icon: accountlistIcon,
    code: 'platform:user',
    isVerify: false
  },
];
